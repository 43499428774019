









import { Component, Vue } from "vue-property-decorator";
import Banner from "./Banner.vue";
import CompetitionDetails from "./Details.vue";

@Component({
  metaInfo: {
    title: "Hakkımızda"
  },
  components: {
    Banner,
    CompetitionDetails
  }
})
export default class Index extends Vue {}
